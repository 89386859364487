/* globals braintree */
var validators = require('../utils/validators');
var weForm = require('../utils/weForm');
var weAjax = require('../utils/weAjax');

var currentYear = (new Date()).getUTCFullYear();
var ccYearsRange = [];

for (var year = 0; year <= 21; year++) {
  ccYearsRange[year] = currentYear + year;
}

var hostedFields = {
  cardholderName: {
    selector: '#inputCardholderName',
    placeholder: 'Ex. William Smith'
  },
  number: {
    selector: '#inputCardNumber',
    placeholder: 'Ex. 1234567887654321',
    maxCardLength: 16
  },
  cvv: {
    selector: '#inputCvvNumber',
    placeholder: 'Ex. 555'
  },
  expirationMonth: {
    selector: '#selectMonth',
    placeholder: 'Select Month',
    select: {
      options: [
        '01 - January',
        '02 - February',
        '03 - March',
        '04 - April',
        '05 - May',
        '06 - June',
        '07 - July',
        '08 - August',
        '09 - September',
        '10 - October',
        '11 - November',
        '12 - December'
      ]
    }
  },
  expirationYear: {
    selector: '#selectYear',
    placeholder: 'Select Year',
    select: {
      options: ccYearsRange
    }
  }
};

var hostedFieldsStyle = {
  input: {
    'font-size': '14px',
    'line-height': '1.42857143',
    'color': '#555'
  }
};

var localFields = {
  email: {
    selector: '#email',
    validator: validateEmail,
    validateOn: ['blur']
  },
  acceptTerms: {
    selector: '#acceptTermsCheckbox',
    validator: validateCheckbox,
    validateOn: ['change']
  }
};

function resetValidationCSS(container, isValid, isNeutral) {
  var errors = $('.help-block', container);
  
  container.removeClass('has-error');
  container.removeClass('has-success');
  errors.addClass('hidden');

  if (isNeutral === true) {
    return;
  }

  if (isValid === true) {
    container.addClass('has-success');
  }
  if (isValid === false) {
    container.addClass('has-error');
    errors.removeClass('hidden');
  }
}

function validateEmail(element) {
  var value = element.val();
  var container = $(element).parents('.form-group');
  var isValid = validators.emailValidationRegex.test(value);

  resetValidationCSS(container, isValid);

  return isValid === true;
}

function validateCheckbox(element) {
  var container = $(element).parents('.form-group');
  var isValid = $(element).is(':checked');

  resetValidationCSS(container, isValid);

  return isValid === true;
}

function validateHostedField(field) {
  var container = $(field.container).parents('.form-group');

  if (field.isValid) {
    resetValidationCSS(container, true)
  } else {
    resetValidationCSS(container, false);
  }
  return field.isValid === true;
}

function updateHostedFieldCSS(event, name) {
  validateHostedField(event.fields[event.emittedBy]);
}

function disableHostedFieldsValidation(hfInstance) {
  hfInstance.off('validityChange', updateHostedFieldCSS);
  hfInstance.off('blur', updateHostedFieldCSS);
  hfInstance.off('empty', updateHostedFieldCSS);
}

function addHostedFieldsValidation(hfInstance) {
  hfInstance.on('validityChange', updateHostedFieldCSS);
  hfInstance.on('blur', updateHostedFieldCSS);
  hfInstance.on('empty', updateHostedFieldCSS);
}

function addLocalFieldsValidation(purchaseForm) {
  $.each(localFields, function (key, field) {
    var input = $(field.selector);
    var events = field.validateOn;
    events.forEach(function (eName) {
      input.on(eName, function () {
        field.validator(input);
      });
    });
  })
}

function resetCCDetails(hfInstance) {
  var keys = ['number', 'cvv', 'expirationMonth', 'expirationYear'];
  disableHostedFieldsValidation(hfInstance);

  $.each(keys, function (index, key) {
    hfInstance.clear(key);

    var container = $(hostedFields[key].selector).parents('.form-group');
    resetValidationCSS(container, false, true);
  });

  setTimeout(function () {
    addHostedFieldsValidation(hfInstance);
  }, 1000);
}

function showPurchaseErrModalAndScroll(modalId, hfInstance) {
  var errorModal = $(modalId);
  var checkoutFormOffset = $('.secure-checkout').offset() || { top: 275 };

  $('form .error').addClass('hidden');

  errorModal.modal({ show: true });
  errorModal.on('hidden.bs.modal', function () {
    $('body').scrollTop(checkoutFormOffset.top);
    hfInstance.focus('number');
  });
}

function isTryAnotherAction(jqXhr) {
  var responseObject = jqXhr.responseJSON;
  return responseObject && responseObject.weRouteMessage && responseObject.weRouteMessage.action === 'tryAnother';
}

function sendInitError(err, type) {
  WE.events.push('purchaseForm', 'I', {inp_state: type, inp_value: err.message}, false);
  setTimeout(function () {
    window.location.reload();
  }, 2500);
}

function sendNonceEvent(err, providerAbbr) {
  WE.events.push('nonce', providerAbbr, {
    inp_state: err ? 'ERR' : 'VALID',
    inp_value: err ? (err.message || err.weRouteMessage || 'Error generating NONCE') : 'EMPTY'
  }, false);
}

function sendRiskDataEvent(err) {
  WE.events.push('riskData', 'F', {
    inp_state: !err ? 'VALID' : 'ERR',
    inp_value: !err ? 'EMPTY' : (err || 'Error generating RISK DATA')
  }, false);
}

function getDeviceData(clientInstance, callback) {
  braintree.dataCollector.create({client: clientInstance}, function (err, dcInstance) {
    sendRiskDataEvent(err);
    callback(dcInstance && dcInstance.deviceData);
  });
}

function sendGCapReqEvent(providerAbbr) {
  WE.events.push('gcapV3_REQ', providerAbbr, {
    inp_state: 'REQ',
    inp_value: 'EMPTY'
  }, false);
}

function sendGCapRespEvent(err, providerAbbr) {
  WE.events.push('gcapV3_RESP', providerAbbr, {
    inp_state: err ? 'ERR' : 'VALID',
    inp_value: err ? (err.message || 'Error generating GCAP V3 token') : 'EMPTY'
  }, false);
}

function getReCaptchaV3Token(callback) {
  var pending = true;
  
  sendGCapReqEvent('BT');

  if (!window.grecaptcha) {
    sendGCapRespEvent({message: 'JS Load Failure'}, 'BT');
    return callback();
  }

  var trackingAction = $('input.trackingKeyBase').val();
  window.grecaptcha.execute(window.reCaptchaV3Key, {action: trackingAction}).then(function (token) {
    pending && callback(token);
    pending = false;
    sendGCapRespEvent(null, 'BT');
  });
  
  setTimeout(function () {
    pending && callback();
    pending = false;
    sendGCapRespEvent({message: 'Token Timeout'}, 'BT');
  }, 10000);
}

function isFormValid(hfInstance, errorFields) {
  var formIsValid = true;
  var hfState = hfInstance.getState();

  Object.keys(hfState.fields).forEach(function(field) {
    var isFieldValid = validateHostedField(hfState.fields[field]);
    if (!isFieldValid) {
      errorFields.push(field);
    }
    formIsValid = isFieldValid && formIsValid;
  });

  Object.keys(localFields).forEach(function(key) {
    var field = localFields[key];
    var input = $(field.selector);
    var isFieldValid = field.validator(input);
    if (!isFieldValid) {
      errorFields.push(key);
    }
    formIsValid = isFieldValid && formIsValid;
  });

  return formIsValid;
}

function getPurchaseData(hfPayload, $form) {
  return {
    provider: 'braintree',
    plan: weForm.inputValue($form, 'plan'),
    payload: weForm.inputValue($form, 'payload'),
    email: weForm.inputValue($form, 'email'),
    name: hfPayload.details.cardholderName,
    bin: hfPayload.details.bin,
    last4: hfPayload.details.lastFour,
    payment_method_nonce: hfPayload.nonce
  };
}

function sendPurchaseRequest(purchaseData, purchaseUrl, hfInstance, doneSubmit) {
  weAjax.post(purchaseUrl, purchaseData, function () {
    doneSubmit();
  }, function (jqXhr) {
    doneSubmit();
    var tryAnotherCard = isTryAnotherAction(jqXhr);
    if(tryAnotherCard) {
      resetCCDetails(hfInstance);
      showPurchaseErrModalAndScroll('#tryAnotherCardModal', hfInstance);
    }
  });
}

function addPaymentFormHandlers(paymentForm, purchaseButton, purchaseUrl, clientInstance, hfInstance) {
  var submitInProgress = false;

  function doneSubmit() {
    submitInProgress = false;
    purchaseButton.attr('disabled', false);
  }

  purchaseButton.click(function () {
    paymentForm.trigger('submit');
  });

  paymentForm.submit(function(e) {
    if(submitInProgress) { return; }

    submitInProgress = true;
    e.preventDefault();
    $('form .error').addClass('hidden');
    purchaseButton.attr('disabled', true);

    var errorFields = [];
    var isValid = isFormValid(hfInstance, errorFields);

    WE.events.push('purchaseButton', 'C', {inp_state: 'clicked', errors: errorFields.join(',') || 'none'}, false);

    if (isValid === false) {
      return doneSubmit();
    }

    WE.events.push('purchaseForm', 'S', {inp_state: 'submit'}, false);

    hfInstance.tokenize(function(err, hfpayload) {
      sendNonceEvent(err, 'BT');
      if (err) {
        return doneSubmit();
      }
      var purchaseData = getPurchaseData(hfpayload, paymentForm);
      getDeviceData(clientInstance, function (deviceInfo) {
        purchaseData.device = deviceInfo;
        getReCaptchaV3Token(function (token) {
          purchaseData.token = token;
          sendPurchaseRequest(purchaseData, purchaseUrl, hfInstance, doneSubmit);
        });
      });
    });

  });
}

function initPurchaseForm(paymentForm, purchaseButton, purchaseUrl, clientToken) {
  braintree.client.create({authorization: clientToken}, function(err, clientInstance) {
    if (err) {
      return sendInitError(err, 'bt_client_init');
    }
    braintree.hostedFields.create({client: clientInstance, fields: hostedFields, styles: hostedFieldsStyle}, function(err, hfInstance) {
      if (err) {
        return sendInitError(err, 'bt_hf_init');
      }
      addHostedFieldsValidation(hfInstance);
      addLocalFieldsValidation(paymentForm);
      addPaymentFormHandlers(paymentForm, purchaseButton, purchaseUrl, clientInstance, hfInstance);
    });
  });
}

module.exports = function (purchaseUrl) {
  var pForm = $('#paymentForm');
  var pButton = $('button[name="purchaseButton"]');
  weAjax.getPaymentProvider({default: 'true'}, function (provider, paymentWebToken) {
    initPurchaseForm(pForm, pButton, purchaseUrl, paymentWebToken);
  });
  $('#purchaseTooltipWhatThis').tooltip();
};
